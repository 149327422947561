export const MODAL_INFO_MESSAGES = {
  SAVE_TITLE: 'Сохранение',
  REMOVE_TITLE: 'Удаление',
  REMOVE_SUCCESS: 'Группа удалена.',
  REMOVE_SCENARIO_SUCCESS: 'Сценарий удален.',
  REMOVE_ERROR: 'Группа не удалена.',
  REMOVE_SCENARIO_ERROR: 'Не удалось удалить сценарий.',
  SAVE_SCENARIO_ERROR: 'Не удалось сохранить сценарий.',
  SAVE_SCENARIO_ERROR_STEP: 'Сценарий не содержит этапов.',
  COPY_SCENARIO_SUCCESS: 'Сценарий успешно скопирован.',
  COPY_SCENARIO_ERROR: 'Не удалось скопировать сценарий.',
  VALIDATION_ERROR: 'Проверьте правильность заполнения полей.',
};

export const OPTIONS_CHOICE = [
  {
    value: 1,
    label: 'Да',
  },
  {
    value: 2,
    label: 'Нет',
  },
];

export const ID_CHOICE_YES = 1;
export const ID_CHOICE_NO = 2;

export const ID_PROCESS_TYPE_PROCEDURE = 1;
export const ID_PROCESS_TYPE_OPERATIONS = 2;

export const LABEL_NAME_ITEMS_SIGNS = {
  number: '№',
  typeProcess: 'Тип процесса',
  name: 'Название',
  notNecessary: 'Необязательно',
  runsInParallel: 'Выполняется параллельно',
};

export const TRADING_OPERATION_ID = {
  export: '1',
  import: '2',
  transit: '3',
};

export const TINY_OPTIONS = {
  height: 500,
  menubar: false,
  plugins: [
    'advlist autolink lists link charmap preview anchor',
    'searchreplace visualblocks code',
    'media table image imagetools paste code wordcount',
  ],
  toolbar:
    'undo redo | formatselect | bold italic backcolor forecolor | \
    alignleft aligncenter alignright alignjustify | \
    bullist numlist outdent indent | image table | removeformat | help',
  // поле заголовка в диалоговом окне изображения
  image_title: true,
  // разрешаем автоматическую загрузку изображений, представленных blob-объектами или данными по url
  automatic_uploads: true,
  // пользовательский инмтрумент выбора файлов в диалоговое окно изображения
  file_picker_types: 'image',
  // функция загрузки изображения
  file_picker_callback: function (cb) {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');

    input.onchange = function () {
      const file = this.files[0];
      const reader = new FileReader();

      reader.onload = function () {
        const id = 'blobid' + new Date().getTime();
        const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
        const base64 = reader.result.split(',')[1];
        const blobInfo = blobCache.create(id, file, base64);
        blobCache.add(blobInfo);

        // обратный вызов с заполнением поля title именем файла
        cb(blobInfo.blobUri(), { title: file.name });
      };
      reader.readAsDataURL(file);
    };
    // выполняем по клику
    input.click();
  },
  language: 'ru',
  resize: false,
};

// scenarios constants
export const STEP = {
  id: 0,
  name: 'Операция 1',
  isParallel: false,
  isNotNecessary: false,
  orderNum: 1,
  isActive: true,
  destinationCountryIds: [],
  departureCountryIds: [],
  stepActions: [],
  stepVideos: [],
  stepMaterials: [],
  stepLegalSourceIds: [],
  stepParticipants: [],
  stepRequiredDocumentIds: [],
  stepResultDocumentIds: [],
  isTemplate: false,
  isNew: true,
  templateId: null,
  duration: 0,
  hour: 0,
  minute: 0,
  cost: 0,
  isHourFormat: true,
  durationMins: 0,
};

export const PROCEDURE = {
  id: 0,
  name: 'Процедура 1',
  isParallel: false,
  isNotNecessary: false,
  orderNum: 1,
  isActive: true,
  isTemplate: false,
  isNew: true,
  templateId: null,
  duration: 0,
  hour: 0,
  minute: 0,
  cost: 0,
  steps: [STEP],
};

export const STAGE = {
  id: 0,
  name: 'Этап 1',
  isParallel: false,
  isNotNecessary: false,
  orderNum: 1,
  isActive: true,
  isTemplate: false,
  isNew: true,
  templateId: null,
  duration: 0,
  hour: 0,
  minute: 0,
  cost: 0,
  procedures: [PROCEDURE],
};

export const OrganizationRoleId = {
  REGULATOR: 1,
  PARTICIPANT: 2,
};

export const SCENARIOS_NODE_KIND = {
  stage: 0,
  procedure: 1,
  step: 2,
};

export const SCENARIOS_NODE_KIND_CLASS = {
  0: 'stage',
  1: 'procedure',
  2: 'step',
};

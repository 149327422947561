<template>
  <div class="products-groups-card">
    <div class="container-page big">
      <h2 class="title title--h2">Карточка товара</h2>
      <form>
        <div class="products-groups-card__base-fields">
          <div class="products-groups-card__cell-12">
            <div class="label-field">Товар (ТН ВЭД) <span>*</span></div>
            <SelectionGoods
              :selectedIdsProps="form.tnved"
              :class="{ error: v$.form.tnved.$error }"
              :multiple="false"
              @updateSelectedIDs="updateSelectedIDs"
            />
            <p v-if="v$.form.tnved.$error" class="invalid-msg">Поле обязательно для заполнения</p>
          </div>
          <div class="products-groups-card__cell-12">
            <div class="label-field">Тип торговой операции <span>*</span></div>
            <SelectMultiComponent
              v-model="form.tradingOperation"
              :options="getTypeOfTradingOperationMultiSelect"
              placeholder="Выберите"
              :classSelect="{ error: v$.form.tradingOperation.$error }"
              modifier="rectangular"
              required
              clearClass
            />
            <p v-if="v$.form.tradingOperation.$error" class="invalid-msg">Поле обязательно для заполнения</p>
          </div>
          <div class="products-groups-card__cell-12">
            <div class="label-field">Страны <span>*</span></div>
            <SelectMultiComponentCheckbox
              v-model="form.countries"
              :options="getCountriesSelect"
              placeholder="Выберите"
              :error="v$.form.countries.$error"
              labelOptions="text"
              trackBy="text"
              valueProp="id"
              mod="base"
              required
            />
          </div>
          <div class="products-groups-card__cell-12">
            <div class="label-field">Страна транзита</div>
            <SelectMultiComponent
              v-model="form.transitCountry"
              :options="getCountriesMultiSelect"
              placeholder="Выберите"
              modifier="rectangular"
              clearClass
            />
          </div>
        </div>
        <div class="products-groups-card__signs">
          <h3 class="title title--products-signs">Признаки товара</h3>
          <template v-if="form.tnvedAttributes.length">
            <div class="item-signs head">
              <p class="item-signs__cell item-signs__cell-number">{{ label.number }}</p>
              <p class="item-signs__cell item-signs__cell-type">{{ label.typeProcess }}</p>
              <p class="item-signs__cell item-signs__cell-name">{{ label.name }}</p>
              <p class="item-signs__cell item-signs__cell-required">{{ label.notNecessary }}</p>
              <p class="item-signs__cell item-signs__cell-parallel">{{ label.runsInParallel }}</p>
            </div>
            <ItemSigns
              v-for="(item, index) in form.tnvedAttributes"
              :key="item.id || index"
              :typeProps="item.type"
              :entityIdProps="item.entityId"
              :notNecessaryProps="item.notNecessary"
              :parallelProps="item.parallel"
              :index="index"
              :class="{ last: lastIndexAttributes === index }"
              :optionsType="getTypeProcess"
              @onDelete="onDeleteAttributes"
              @changeValue="changeValueAttributes"
            />
          </template>
          <div class="products-groups-card__add-signs">
            <ButtonComponent @click.prevent.stop="onAddAttributes" mod="transparent-bg"
              >Добавить признак</ButtonComponent
            >
          </div>
        </div>
        <div class="products-groups-card__checkbox-wrap">
          <ComponentInput
            id="active-card"
            type="checkbox"
            label="Запись активна"
            mod="checkbox-green"
            v-model="form.isActive"
          />
        </div>
        <div class="products-groups-card__button-wrap">
          <ButtonComponent @click.prevent.stop="onSave" mod="gradient-bg" class="button-redesign--map-apply"
            >Сохранить</ButtonComponent
          >
          <ButtonComponent @click.prevent.stop="onClose">Отменить</ButtonComponent>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { useVuelidate } from '@vuelidate/core';
  import { required } from '@vuelidate/validators';

  import ComponentInput from '@/common/components/ComponentInput.vue';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import SelectionGoods from '@/common/components/redesigned/SelectionGoods.vue';
  import SelectMultiComponentCheckbox from '@/common/components/redesigned/SelectRedesigned/SelectMultiComponentCheckbox.vue';
  import SelectMultiComponent from '@/common/components/SelectMultiComponent.vue';
  import Constants from '@/common/constants';

  import ItemSigns from '../components/ItemSigns.vue';
  import { NAVIGATOR_PRODUCT_GROUPS_ACTIONS_NAME } from '../store/productGroups';
  import { ID_CHOICE_NO, ID_CHOICE_YES, LABEL_NAME_ITEMS_SIGNS } from '../utils/constants';

  import { DIRECTIVES_ACTIONS_NAME, DIRECTIVES_GETTERS_NAME } from '@/store/directives';

  export default {
    name: 'ProductGroupsCard',
    components: {
      SelectMultiComponent,
      ButtonComponent,
      ComponentInput,
      ItemSigns,
      SelectionGoods,
      SelectMultiComponentCheckbox,
    },
    data() {
      return {
        isEdit: this.$route.params.type === 'edit',
        IDEdit: this.$route.params.id,
        label: LABEL_NAME_ITEMS_SIGNS,
        form: {
          tnved: [],
          tradingOperation: null,
          countries: [],
          transitCountry: null,
          isActive: false,
          tnvedAttributes: [],
          created: '',
        },
        attributesSkeleton: {
          type: null,
          entityId: null,
          notNecessary: ID_CHOICE_NO,
          parallel: ID_CHOICE_NO,
        },
      };
    },
    computed: {
      ...mapGetters({
        getCountriesSelect: DIRECTIVES_GETTERS_NAME.getCountriesSelect,
        getCountriesMultiSelect: DIRECTIVES_GETTERS_NAME.getCountriesMultiSelect,
        getTypeOfTradingOperationMultiSelect: DIRECTIVES_GETTERS_NAME.getTypeOfTradingOperationMultiSelect,
        getTypeProcess: DIRECTIVES_GETTERS_NAME.getTypeProcess,
      }),

      lastIndexAttributes() {
        return this.form.tnvedAttributes.length - 1;
      },
    },
    created() {
      this.loadPage();
      if (this.isEdit) this.loadCard();
    },
    methods: {
      ...mapActions({
        countriesGet: DIRECTIVES_ACTIONS_NAME.countriesGet,
        getTypeOfTradingOperation: DIRECTIVES_ACTIONS_NAME.getTypeOfTradingOperation,
        getProcessType: DIRECTIVES_ACTIONS_NAME.getProcessType,
        getProcedure: DIRECTIVES_ACTIONS_NAME.getProcedure,
        getOperations: DIRECTIVES_ACTIONS_NAME.getOperations,
      }),

      ...mapActions({
        createGroup: NAVIGATOR_PRODUCT_GROUPS_ACTIONS_NAME.createGroup,
        updateGroup: NAVIGATOR_PRODUCT_GROUPS_ACTIONS_NAME.updateGroup,
        findGroup: NAVIGATOR_PRODUCT_GROUPS_ACTIONS_NAME.findGroup,
      }),

      async loadPage() {
        await this.countriesGet();
        await this.getTypeOfTradingOperation();
        await this.getProcessType();
        await this.getProcedure();
        await this.getOperations();
      },

      async loadCard() {
        const res = await this.findGroup({ id: this.IDEdit });
        if (res.success) {
          const {
            tnved = {},
            tradingOperation = {},
            countries = [],
            transitCountry = {},
            tnvedAttributes = [],
            isActive = false,
            created = '',
          } = res.data;
          this.form.tnved = [tnved.id];
          this.form.tradingOperation = tradingOperation?.id;
          this.form.countries = countries.map((i) => i.id);
          this.form.transitCountry = transitCountry?.id;
          this.form.tnvedAttributes = tnvedAttributes.map((i, index) => ({
            id: index + 1,
            entityId: i.entityId,
            notNecessary: i.notNecessary ? ID_CHOICE_YES : ID_CHOICE_NO,
            parallel: i.parallel ? ID_CHOICE_YES : ID_CHOICE_NO,
            type: i.processType?.id,
          }));
          this.form.isActive = isActive;
          this.form.created = created;
        }
      },

      closeModal(type) {
        this[type] = false;
      },

      onAddAttributes() {
        this.form.tnvedAttributes.push({
          ...this.attributesSkeleton,
          id: this.form.tnvedAttributes.length + 1,
        });
      },

      onDeleteAttributes(index) {
        this.form.tnvedAttributes.splice(index, 1);
      },

      changeValueAttributes(params) {
        const { val, type, index } = params;
        this.form.tnvedAttributes[index][type] = val;
      },

      async onSave() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Сохранение', Constants.validationError, 'error');
          return false;
        }
        if (this.form.tnvedAttributes.length) {
          this.form.tnvedAttributes = this.form.tnvedAttributes.filter((i) => i.type);
        }
        const formData = {
          tnved: {
            id: this.form.tnved[0],
          },
          tradingOperation: {
            id: this.form.tradingOperation,
          },
          countries: this.form.countries.filter((i) => i != Constants.countryRussiaId).map((i) => ({ id: i })),
          isActive: this.form.isActive,
          tnvedAttributes: this.form.tnvedAttributes.map((i) => ({
            id: i.id,
            processType: { id: i.type },
            entityId: i?.entityId || 0,
            notNecessary: i.notNecessary !== ID_CHOICE_NO,
            parallel: i.parallel !== ID_CHOICE_NO,
          })),
        };
        if (this.form.transitCountry) {
          formData.transitCountry = { id: this.form.transitCountry };
        }

        if (this.form.created) formData.created = this.form.created;

        let res = {};

        if (this.isEdit) {
          res = await this.updateGroup({
            ...formData,
            id: this.IDEdit,
          });
        } else {
          res = await this.createGroup(formData);
        }

        if (res.success) {
          Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
          this.onClose();
        } else {
          Constants.alert.fire('Сохранение', Constants.commonError, 'error');
        }
      },

      onClose() {
        this.$router.push({ name: 'ProductGroups' });
      },

      updateSelectedIDs(ids) {
        this.form.tnved = ids;
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations: {
      form: {
        tnved: { required },
        tradingOperation: { required },
        countries: { required },
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/assets/theme/default/title.scss';
  @import '../styles/item-signs.scss';

  .products-groups-card {
    &__cell {
      &-12 {
        margin-bottom: 24px;
      }
    }

    &__checkbox-wrap,
    &__signs {
      margin-bottom: 40px;
    }

    &__base-fields {
      max-width: 740px;
    }

    @media (max-width: $laptop) {
      &__checkbox-wrap,
      &__signs {
        margin-bottom: 24px;
      }
    }
  }
</style>

<template>
  <div class="selection-goods">
    <div
      class="selection-goods__content"
      :class="{ 'selection-goods__content_error': v$.selectedIdsProps.$error && v$.selectedIdsProps.required.$invalid }"
    >
      <div class="selection-goods__selected-items">
        <p v-for="item in getSelectedList" :key="item.id" class="selection-goods__selected-item">
          <template v-if="codeOnly">
            {{ item.code }}
          </template>
          <template v-else> {{ item.code }} - {{ item.name }} </template>
          <span v-if="deleting" class="selection-goods__icon-delete" @click.prevent.stop="deleteItem(item.id)"></span>
        </p>
        <p v-if="!getSelectedList.length" class="selection-goods__placeholder">Выберите</p>
      </div>
      <div
        v-if="getSelectedList.length > 2"
        class="selection-goods__custom-button delete"
        @click.prevent.stop="deleteAll"
      >
        Очистить всё
      </div>
      <div class="selection-goods__custom-button add" @click.prevent.stop="catalogModal = true">Список</div>

      <ModalComponent
        headType="redesigned"
        v-model="catalogModal"
        :width="950"
        title="Выбор товара"
        @close="closeCatalog"
      >
        <CatalogSelectorRedesigned
          :selected-ids="selectedIDs"
          route="nsitnved"
          :multiple="multiple"
          modClass="redesigned"
          @select="updateProducts"
          @close="closeCatalog"
          :select-groups-only="selectGroupsOnly"
          :select-leafs-only="selectLeafsOnly"
          :codes="codes"
        ></CatalogSelectorRedesigned>
      </ModalComponent>
    </div>

    <div class="invalid-msg" v-if="v$.selectedIdsProps.$error">
      <template v-if="v$.selectedIdsProps.required.$invalid">Поле обязательно для заполнения</template>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import { useVuelidate } from '@vuelidate/core';
  import { requiredIf } from '@vuelidate/validators';

  import ModalComponent from '@/components/modals/ModalComponent';

  import CatalogSelectorRedesigned from '@/modules/nsi/components/CatalogSelectorRedesigned';

  import { NSITNVED, NSITNVED_ACTIONS_NAME, NSITNVED_GETTERS_NAME, NSITNVED_MUTATIONS_NAME } from '@/store/nsitnved';

  export default {
    name: 'SelectionGoods',
    emits: ['updateSelectedIDs', 'selectSelectedIDs'],
    components: {
      ModalComponent,
      CatalogSelectorRedesigned,
    },
    props: {
      selectedIdsProps: {
        type: Array,
        default: () => [],
      },
      multiple: {
        type: Boolean,
        default: true,
      },
      codeOnly: {
        type: Boolean,
        default: true,
      },
      deleting: {
        type: Boolean,
        default: true,
      },
      required: {
        type: Boolean,
        default: false,
      },
      localeState: {
        type: Boolean,
        default: false,
      },
      selectGroupsOnly: {
        type: Boolean,
        default: false,
      },
      selectLeafsOnly: {
        type: Boolean,
        default: false,
      },
      codes: {
        type: Array,
        default() {
          return [];
        },
      },
    },
    data() {
      return {
        catalogModal: false,
        selectedIDs: [],
        localeNSITnvedByIDsList: [],
      };
    },
    computed: {
      ...mapGetters({ getNSITnvedByIDsList: NSITNVED_GETTERS_NAME.getNSITnvedByIDsList }),
      getSelectedList() {
        return this.localeState ? this.localeNSITnvedByIDsList : this.getNSITnvedByIDsList;
      },
    },
    mounted() {
      this.deleteAll();
      if (this.selectedIdsProps?.length) {
        this.changeValue();
      }
    },
    methods: {
      ...mapActions({ getNSITnvedByIDs: NSITNVED_ACTIONS_NAME.getNSITnvedByIDs }),
      ...mapMutations({
        setNSITnvedByIDs: `${NSITNVED}/${NSITNVED_MUTATIONS_NAME.setNSITnvedByIDs}`,
        setNSITnvedByIDsDelete: `${NSITNVED}/${NSITNVED_MUTATIONS_NAME.setNSITnvedByIDsDelete}`,
      }),

      async updateProducts(selectedIds) {
        this.selectedIDs = selectedIds;
        const res = await this.getNSITnvedByIDs({ ids: selectedIds });
        this.localeNSITnvedByIDsList = res.data;
        this.$emit('selectSelectedIDs', this.selectedIDs);
        if (res.success) this.closeCatalog();
      },

      closeCatalog() {
        this.catalogModal = false;
      },

      deleteItem(id) {
        this.selectedIDs = this.selectedIDs.filter((i) => +i !== +id);
        this.setNSITnvedByIDsDelete(id);
        this.localeNSITnvedByIDsList = this.localeNSITnvedByIDsList.filter((item) => item.id !== id);
      },

      deleteAll() {
        this.selectedIDs = [];
        this.setNSITnvedByIDs({ data: [] });
        this.localeNSITnvedByIDsList = [];
      },

      async changeValue() {
        if (this.selectedIdsProps.length) {
          await this.getNSITnvedByIDs({ ids: this.selectedIdsProps });
          this.localeNSITnvedByIDsList = JSON.parse(JSON.stringify(this.getNSITnvedByIDsList));
        }
        this.selectedIDs = this.selectedIdsProps;
      },
    },
    watch: {
      selectedIDs: {
        handler(val) {
          this.$emit('updateSelectedIDs', val);
        },
        deep: true,
      },
      selectedIdsProps() {
        this.changeValue();
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        selectedIdsProps: {
          required: requiredIf(this.required),
        },
      };
    },
  };
</script>

<style scoped lang="scss">
  .selection-goods {
    display: flex;
    flex-direction: column;

    &__content {
      display: flex;
      align-items: center;
      border-radius: 4px;
      border: 1px solid $grey;
      background: $light-grey;
      padding: 9px 14px;
      gap: 16px;

      &_error {
        border-color: $red;
      }
    }

    &__custom-button {
      font: $fira-14-20;
      cursor: pointer;
      transition: color 0.3s ease;
      &.add {
        color: $blue;

        &:hover {
          color: $blue-2;
        }
      }

      &.delete {
        color: $dark-grey-1;

        &:hover {
          color: $gray-silver-dark;
        }
      }
    }

    &__placeholder {
      color: $dark-grey-1;
      font: $fira-14-20;
    }

    &__selected {
      &-items {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
      }

      &-item {
        font: $fira-14-20;
      }
    }

    &__icon-delete {
      position: relative;
      display: inline-block;
      width: 16px;
      height: 16px;
      transform: translateY(4px);
      cursor: pointer;

      &::after {
        content: '';
        position: absolute;
        background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M8.30059 1.70032L5.00076 5.00015M5.00076 5.00015L1.70093 8.29998M5.00076 5.00015L1.70093 1.70032M5.00076 5.00015L8.30059 8.29998' stroke='%23989898' stroke-width='1.5' stroke-linecap='square' stroke-linejoin='round'/%3E %3C/svg%3E")
          center no-repeat;
        width: 100%;
        height: 100%;
      }
    }

    @media (max-width: $mobile) {
      gap: 6px;
      &__custom-button {
        &.delete {
          display: none;
        }
      }
    }
  }
</style>

<template>
  <div class="item-signs">
    <div class="item-signs__cell item-signs__cell-number">{{ index + 1 }}</div>
    <div class="item-signs__cell item-signs__cell-type">
      <div class="label-field mobile-visible">{{ label.typeProcess }}</div>
      <SelectMultiComponent v-model="type" placeholder="Выберите" :options="optionsType" modifier="rectangular" />
    </div>
    <div class="item-signs__cell item-signs__cell-name">
      <div class="label-field mobile-visible">{{ label.name }}</div>
      <SelectMultiComponent
        v-model="entityId"
        placeholder="Выберите"
        modifier="rectangular"
        :disabled="!type"
        :options="getOptionsName"
      />
    </div>
    <div class="item-signs__cell item-signs__cell-required">
      <div class="label-field mobile-visible">{{ label.notNecessary }}</div>
      <SelectMultiComponent
        v-model="notNecessary"
        placeholder="Нет"
        modifier="rectangular"
        :options="optionsChoice"
        :disabled="!entityId"
      />
    </div>
    <div class="item-signs__cell item-signs__cell-parallel item-signs__cell-inner">
      <div class="item-signs__select-wrap">
        <div class="label-field mobile-visible">{{ label.runsInParallel }}</div>
        <SelectMultiComponent
          v-model="parallel"
          placeholder="Нет"
          modifier="rectangular"
          :options="optionsChoice"
          :disabled="!entityId"
        />
      </div>
      <IconComponent
        class="icon--full-svg delete item-signs__icon-delete"
        name="delete-not-fill"
        @click.prevent.stop="onDeleteSigns"
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  import SelectMultiComponent from '@/common/components/SelectMultiComponent.vue';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import { ID_CHOICE_NO, ID_PROCESS_TYPE_PROCEDURE, LABEL_NAME_ITEMS_SIGNS, OPTIONS_CHOICE } from '../utils/constants';

  import { DIRECTIVES_GETTERS_NAME } from '@/store/directives';

  export default {
    name: 'ItemSigns',
    components: {
      SelectMultiComponent,
      IconComponent,
    },
    props: {
      typeProps: {
        type: [Number, String],
        default: null,
      },
      entityIdProps: {
        type: [Number, String],
        default: null,
      },
      notNecessaryProps: {
        type: [Number, String],
        default: null,
      },
      parallelProps: {
        type: [Number, String],
        default: null,
      },
      index: {
        type: [Number, String],
        default: 0,
      },
      optionsType: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        label: LABEL_NAME_ITEMS_SIGNS,
        optionsChoice: OPTIONS_CHOICE,
        type: this.typeProps,
        entityId: this.entityIdProps,
        notNecessary: this.notNecessaryProps || ID_CHOICE_NO,
        parallel: this.parallelProps || ID_CHOICE_NO,
      };
    },
    computed: {
      ...mapGetters({
        getProcedureList: DIRECTIVES_GETTERS_NAME.getProcedureList,
        getOperationsList: DIRECTIVES_GETTERS_NAME.getOperationsList,
      }),

      getOptionsName() {
        return this.type === ID_PROCESS_TYPE_PROCEDURE ? this.getProcedureList : this.getOperationsList;
      },
    },
    watch: {
      type(val) {
        this.changeValue('type', val);
        this.entityId = null;
      },
      entityId(val) {
        this.changeValue('entityId', val);
      },
      notNecessary(val) {
        this.changeValue('notNecessary', val);
      },
      parallel(val) {
        this.changeValue('parallel', val);
      },
    },
    methods: {
      onDeleteSigns() {
        this.$emit('onDelete', this.index);
      },

      changeValue(type, val) {
        this.$emit('changeValue', { val, type, index: this.index });
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../styles/item-signs.scss';
</style>
